import React from 'react';
import styled from 'styled-components';
import { theme } from "../../utils/theme"


const Card = styled.div`
  height: 400px;
  background-image: url(${props => props.src});
  width: 20%;
  min-width: 28rem;
  position: relative;
  padding: 20px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;


  @media ${props => props.theme.breaks.down('md')} {
    height: 320px;
    background-size: cover;
    min-width: 22rem;
  }

  
  @media ${props => props.theme.breaks.down('sm')} {
    max-height: 250px;
  }
`

const Text = styled.div`
  position: absolute;
  bottom: 20px;
  .name {
    color: ${theme.colors.body};
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .subtitle {
    color: #fff;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
`
 
export default ({src, name, sub}) => {
 
 return (
  <Card src={src}>
    <Text><h5 className="name">{name}</h5><h6 className="subtitle">{sub}</h6></Text>
  </Card>
 )
}