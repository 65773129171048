import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import RowWrapper from '../utils/rowWrapper';
import Explore from '../utils/explore';
import gsap from 'gsap';
import { theme } from "../utils/theme"
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import Card from '../components/team-cards/card';

import Team5 from '../images/team5.jpg';

gsap.registerPlugin(ScrollToPlugin)


const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.body};
  overflow-y: visible;
`

const LandingContent = styled(RowWrapper)`
 min-height: 100vh;
`

const Center = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    top: 50%;
  }
`

const Title = styled.h1`
  display: block;
  font-size: 7rem;
  font-weight: 300;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
    text-align: center;

    &#winning-team-sec {
      margin-top: 5rem;
    }
  }

  
  @media ${props => props.theme.breaks.down('sm')} {
    &#winning-team-sec {
      font-size: 3rem;
    }
  }
`

const SideNote = styled.h6`
  font-weight: 300;
  margin: 15px 0 40px 0;
  font-size: 2.5rem;
  line-height: 1.2;

  @media ${props => props.theme.breaks.down('md')} {
    text-align: center;
    font-size: 2rem;
  }
`

const ImageSection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;

  @media ${props => props.theme.breaks.down('sm')} {
    padding-bottom: 100px;
  }
`

const CardContainer = styled.div`
  /* width: 100%; */
  /* margin-left: 20%; */
  display: flex;
  flex-direction: row;
  margin-top: 7rem;
  text-align: left;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 100%;
    margin-left: 0;
  }

`

const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`

const PostNote = styled.div`
  padding-top: 40px;
  text-align: right;
  padding-right: 20%;
  h5 {
    font-weight: 300;
  }

  @media ${props => props.theme.breaks.down('md')} {
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
  }
`

const TeamPage = ({ data }) => {

  const scroll = () => {
    gsap.to(window, {scrollTo: '.image-sec'})
  }

  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout pageTitle="Creative team">
      <Container>
        <LandingContent className="sec-0">
          <Center>
            <Title>You need a <br /> winning team.</Title>
            <SideNote>(We don’t expect you to remember any <br />
                of their names or titles right now.)</SideNote>
            <Explore click={() => scroll()} sec="couch" sub="1" show top="30%" />
          </Center>
        </LandingContent>
        
        <ImageSection className="image-sec">
          <Title id="winning-team-sec">You need a <br /> winning team.</Title>
          <CardContainer>
            <Card src="https://res.cloudinary.com/celebrand/image/upload/v1622139420/Yoely_x4kfjx.jpg" name="Joel Schwartz" sub={<>Head of Vision</>}></Card>

            <Card src="https://res.cloudinary.com/celebrand/image/upload/v1622139420/Mordechai_fmtpu4.jpg" name="Mordechai Schwartz" sub={<>Head of Integration</>}></Card>

            <Card src="https://res.cloudinary.com/celebrand/image/upload/v1622139420/Binyumin_bbuhfp.jpg" name="B.C. Wallin" sub={<>Creative Director</>}></Card>

            {/* <Card src="https://res.cloudinary.com/celebrand/image/upload/v1618279962/team4_namy99.jpg" name="Chaim Friedrich" sub={<>A very good client <br/> relations representative</>}></Card> */}

            <Card src="https://res.cloudinary.com/celebrand/image/upload/v1622139420/vigi_mk51rm.jpg" name="Vigi Gluckman" sub={<>Head of Designs</>}></Card>

            <Card src={Team5} name="Shaya hershkowitz" sub={<>Head of Clients</>}></Card>
          </CardContainer>
          <PostNote><h5>CELEBRAND HAS ONE THAT'LL HELP YOU GET AHEAD.</h5></PostNote>
          {/* <PostNote><h5>Celebrand has one that’s very good. You can remember <br />their names when you get very good results.</h5></PostNote> */}
        </ImageSection>

         <FixedFooter>
          <SectionFooter></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default TeamPage;